@import url('./font.css');
@import url('./calendar.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full min-h-screen overflow-x-hidden;
  }
  body {
    @apply h-full w-full font-sans dark:bg-black dark:text-white;
  }
  h1 {
    @apply text-[40px] font-black leading-[50px];
  }
  h2 {
    @apply text-[30px] font-black leading-[35px];
  }
  h3 {
    @apply text-[20px] font-black leading-[25px];
  }
  h4 {
    @apply text-[16px] font-black leading-[22px];
  }
}

.loadingQr {
  transition: all;
  transform-origin: left;
  animation: progressBar 15s linear infinite;
  background-color: #642eff;
}

.infinite-scroll-component__outerdiv {
  @apply w-full px-6;
}

.no-action {
  pointer-events: none;
}

@keyframes progressBar {
  0% {
    width: 0px;
  }

  100% {
    width: 100%;
  }
}

/* List */
ul {
  list-style-type: circle;
  margin-left: 20px;
}

ol {
  list-style-type: decimal;
  margin-left: 20px;
}
