@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../../public/assets/fonts/WorkSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
